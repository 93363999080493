import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {dimensions as customDimensions} from 'src/styles';

const {
  defaultPageSidePadding,
  defaultPageSidePaddingMobile,
  sectionVerticalPadding,
  sectionVerticalPaddingMobile,
} = customDimensions;

export const DefaultSectionPadding = styled.div`
  padding: ${sectionVerticalPadding} ${defaultPageSidePadding};

  @media screen and (max-width :${dimensions.breakpoints.tabletLandscape}) {
    padding ${sectionVerticalPaddingMobile} ${defaultPageSidePaddingMobile};
  }
`;
