import {createContext, useContext, useMemo} from 'react';

import {regionConfigs, ValidRegionCode} from 'src/config';
import {LocalizedParams} from 'src/types/LocalizedParams';

export type PageRegionContextProviderProps = LocalizedParams;

export interface PageRegionContextStore extends LocalizedParams {
  regionConfig: typeof regionConfigs[ValidRegionCode];
}

const PageRegionDataContext = createContext<PageRegionContextStore | undefined>(
  undefined
);

export const PageRegionDataContextProvider: React.FC<PageRegionContextProviderProps> =
  (props) => {
    const {locale, region, children} = props;

    const pageRegionContextValue: PageRegionContextStore = useMemo(
      () => ({
        locale,
        region,
        regionConfig: regionConfigs[region],
      }),
      [locale, region]
    );

    return (
      <PageRegionDataContext.Provider value={pageRegionContextValue}>
        {children}
      </PageRegionDataContext.Provider>
    );
  };

export const usePageRegionData = (): PageRegionContextStore => {
  const context = useContext(PageRegionDataContext);

  if (!context) {
    throw new Error(
      'usePageRegionData must be used within a PageRegionDataContextProvider'
    );
  }

  return context;
};
