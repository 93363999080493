import Cookies, {CookieAttributes} from 'js-cookie';

import {isValidRegion, ValidRegionCode} from 'src/config';

const cookieKeys = ['user_pref_region'] as const;

export type validCookieKey = typeof cookieKeys[number];

interface CookiePrototype {
  key: validCookieKey;
}

export interface RegionCookie extends CookiePrototype {
  key: 'user_pref_region';
  value: ValidRegionCode;
}

export type CookiePair = RegionCookie;

export const isRegionCookie = (cookie: CookiePair): cookie is RegionCookie => {
  return cookie.key === 'user_pref_region' && isValidRegion(cookie.value);
};

/**
 * @param cookieKey the name of the cookie
 */
export const getCookie = (cookieKey: validCookieKey): CookiePair | null => {
  const cookieValue = Cookies.get(cookieKey);
  if (!cookieValue) return null;
  switch (cookieKey) {
    case 'user_pref_region': {
      if (isValidRegion(cookieValue)) {
        return {key: cookieKey, value: cookieValue};
      }
      break;
    }
    default: {
      console.log('Failed to get cookie: invalid cookie name');
    }
  }

  return null;
};

const defaultCookieOptions: CookieAttributes = {
  path: '/',
  sameSite: 'lax',
};

/**
 * @param cookie the key=value pair to store as a cookie
 * @param opts cookie options such as expires, path, same-site, etc.
 */
export const setCookie = (
  cookie: CookiePair,
  opts?: CookieAttributes
): void => {
  Cookies.set(cookie.key, cookie.value.toString(), {
    ...defaultCookieOptions,
    ...opts,
  });
};

/**
 * When deleting a cookie and you're not relying on the default attributes,
 * you must pass the exact same path and domain attributes that were used to set the cookie.
 * Source: https://github.com/js-cookie/js-cookie
 * @param cookieKey the name of the cookie
 */
export const removeCookie = (
  cookieKey: validCookieKey,
  opts?: CookieAttributes
): void => {
  Cookies.remove(cookieKey, {...defaultCookieOptions, ...opts});
};
