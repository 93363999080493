import React from 'react';

import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {PageContextProvider, PageContextProps} from 'src/contexts';

import Footer from './Footer';
import {Navbar} from './Navbar';
import {PreviewIndicator} from './PreviewIndicator';
import {RegionHelper} from './RegionHelper';
import {SEO, SEOProps} from './SEO';

export interface LayoutProps {
  pageContext: PageContextProps;
  seo: SEOProps;
  preview?: boolean;
  banners?: React.ReactNode;
  pure?: boolean;
}

const Main = styled.main`
  min-height: calc(100vh - ${dimensions.topNavHeight});
  display: flex;
  flex-flow: column nowrap;
  position: relative;
`;

export const Layout: React.FC<LayoutProps> = (props) => {
  const {children, pageContext, seo, preview, banners, pure} = props;

  return (
    <>
      <PageContextProvider {...pageContext}>
        <SEO {...seo} />

        {!pure && banners}
        {!pure && <Navbar />}
        <Main>{children}</Main>
        {!pure && <Footer />}
        <RegionHelper />

        {preview && <PreviewIndicator />}
      </PageContextProvider>
    </>
  );
};
