import Link, {LinkProps} from 'next/link';

import {ValidRegionCode, SupportedLocale} from 'src/config';
import {usePageRegionData} from 'src/contexts';

import {StyledAnchor} from './StyledAnchor';

export interface InternalLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;

  /** Force a certain locale to be applied. If not provided, user is directed based on the current locale */
  locale?: SupportedLocale;

  /** Force a certain region to be applied. If not provided, user is directed based on the current region */
  region?: ValidRegionCode;

  linkProps?: Partial<LinkProps>;
}

export const InternalLink: React.FC<InternalLinkProps> = (props) => {
  const {href, locale, region, children, linkProps, ...anchorElementAttrs} =
    props;
  const {region: currRegion, locale: currLocale} = usePageRegionData();

  const pathPrefix = `/${region || currRegion}/${locale || currLocale}`;

  return (
    <Link
      href={`${pathPrefix}${href}`}
      passHref
      prefetch={false}
      {...linkProps}
    >
      <StyledAnchor {...anchorElementAttrs}>{children}</StyledAnchor>
    </Link>
  );
};
