import {
  getCountryRegion,
  selectBestLocale,
  isValidRegion,
  regionConfigs,
  DEFAULT_REGION,
  ValidRegionCode,
  extractAcceptedLangsArr,
  SupportedLocale,
  Alpha2Code,
} from 'src/config';
import {LocalizedParams} from 'src/types';

export const getClientLocation = (
  countryCode: Alpha2Code,
  acceptedLangsHeader: string
): LocalizedParams => {
  const region = getClientRegion(countryCode);
  const locale = getClientLocale(region, acceptedLangsHeader);

  return {region, locale};
};

export const getClientRegion = (countryCode: Alpha2Code): ValidRegionCode => {
  const detectedRegion = getCountryRegion(countryCode);
  const region: ValidRegionCode = isValidRegion(detectedRegion)
    ? detectedRegion
    : DEFAULT_REGION;

  return region;
};

export const getClientLocale = (
  region: ValidRegionCode,
  acceptedLangsHeader: string
): SupportedLocale => {
  const {defaultLocale, locales: validLocales} = regionConfigs[region];
  const detectedLocales = extractAcceptedLangsArr(acceptedLangsHeader);
  const locale = selectBestLocale({defaultLocale, validLocales})(
    detectedLocales
  );

  return locale;
};
