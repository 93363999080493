import styled from 'styled-components';

import {colors} from '@stryd/react-ui';

export const StyledAnchor = styled.a`
  transition: 0.1s color ease;

  &:hover {
    color: ${colors.themes.dark.textHighlight};
    text-decoration: none;
  }
`;
