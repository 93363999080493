import styled, {css} from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {dimensions as customDimensions} from 'src/styles';

const {
  defaultPageSidePadding,
  defaultPageSidePaddingMobile,
  defaultPageVerticalPadding,
  defaultPageVerticalPaddingMobile,
} = customDimensions;

export const defaultPagePaddingMixin = css`
  padding: ${defaultPageVerticalPadding} ${defaultPageSidePadding};

  @media screen and (max-width :${dimensions.breakpoints.tabletLandscape}) {
    padding ${defaultPageVerticalPaddingMobile} ${defaultPageSidePaddingMobile};
  }
`;

export const DefaultPagePadding = styled.div`
  ${defaultPagePaddingMixin}
`;
