import {
  PageRegionDataContextProvider,
  PageRegionContextProviderProps,
} from './PageRegionDataContext';
import {ReactIntlProvider, ReactIntlProviderProps} from './ReactIntlContext';

export type PageContextProps = PageRegionContextProviderProps &
  ReactIntlProviderProps;

export const PageContextProvider: React.FC<PageContextProps> = (props) => {
  const {children, locale, region} = props;

  return (
    <PageRegionDataContextProvider locale={locale} region={region}>
      <ReactIntlProvider locale={locale}>{children}</ReactIntlProvider>
    </PageRegionDataContextProvider>
  );
};
