import {useRef, useState} from 'react';

import Image from 'next/image';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {
  dimensions,
  colors,
  ShoppingCartIcon,
  MobileNavMenuButton,
} from '@stryd/react-ui';

import {regionConfigs} from 'src/config/regions';
import {usePageRegionData} from 'src/contexts';
import {useCart} from 'src/features/cart/contexts';
import {useNavigation} from 'src/hooks/useNavigation';
import {visuallyHidden} from 'src/styles/visuallyHidden';
import {withBasePath} from 'src/utils/router';

import {ExternalLink} from '../ExternalLink';
import {InternalLink} from '../InternalLink';
import {Link} from '../Link';

const mobileNavAnimationDuration = 200;
const iconContainerSize = '44px';

const Logo = styled(Image)`
  margin-bottom: 0px;
`;

const NavContainer = styled.nav`
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: ${colors.themes.dark.background};
  color: ${colors.themes.dark.text};
`;

const NavTopBar = styled.div`
  width: 100%;
  height: ${dimensions.topNavHeight};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 60px;

  @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
    padding: 0 24px;
  }
`;

const NavBlockContainer = styled.div`
  > * {
    padding: 1rem;
  }

  @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
    display: none;
  }
`;

const MenuBtnContainer = styled.div`
  display: none;

  @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 6px;

    width: ${iconContainerSize};
    height: ${iconContainerSize};
    padding-left: 4px;
  }
`;

const MobileNavBlockContainer = styled.div`
  display: none;

  @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
    display: block;
    position: absolute;
    top: 75px;
    left: 0px;
    background-color: ${colors.themes.dark.background};
    width: 100%;
    transition: height ${mobileNavAnimationDuration}ms ease;
    overflow: hidden;
  }
`;

const MobileNavBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.25rem;

  > * {
    padding: 1rem;
  }

  a {
    &:last-of-type {
      margin-bottom: 1rem;
    }
  }
`;

const LinkStack = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: ${dimensions.halfPadding};
  }
`;

const ShoppingCartIconContainer = styled.div`
  position: relative;
  width: ${iconContainerSize};
  height: ${iconContainerSize};
  padding: 4px;
  border-radius: ${iconContainerSize};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${colors.themes.dark.backgroundLayers[2]};
  }
`;

const ShoppingCartItemCount = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  width: 20px;
  padding: 4px;
  background: ${colors.themes.dark.textHighlight};
  color: ${colors.themes.dark.headerText};
  display: flex;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
`;

const IconLabelText = styled.span`
  ${visuallyHidden};
`;

export const Navbar: React.FC = () => {
  const [{totalNumberOfItems}] = useCart();
  const {region} = usePageRegionData();
  const intl = useIntl();
  const regionConfig = regionConfigs[region];

  const navigationElements = useNavigation('topNavBar');
  const [mobileNavHeight, setMobileNavHeight] = useState(0);
  const mobileNavRef = useRef<HTMLDivElement | null>(null);

  const selectStoreMessage = intl.formatMessage({
    id: 'Navbar.selectStoreTip',
    defaultMessage: 'Select Store',
  });

  const cartItemsLabel = intl.formatMessage(
    {
      id: 'Navbar.cartLabel',
      defaultMessage: 'Cart Items: {numberOfItems}',
    },
    {numberOfItems: totalNumberOfItems}
  );

  const getMobileMenuHeight = () => {
    if (!mobileNavRef.current) return 0;
    const {height} = mobileNavRef.current?.getBoundingClientRect();
    return height;
  };

  const toggleMobileNav = () => {
    setMobileNavHeight(mobileNavHeight > 0 ? 0 : getMobileMenuHeight());
  };

  const links = navigationElements?.map((el, i) => (
    <Link
      key={i}
      href={el.href}
      style={{
        color: el.highlight ? colors.themes.dark.accentPrimary : undefined,
      }}
    >
      {el.text}
    </Link>
  ));

  return (
    <NavContainer>
      <NavTopBar>
        <ExternalLink href="https://www.stryd.com">
          <Logo
            width={86}
            height={22}
            src={withBasePath(`/images/STRYD-logo.svg`)}
            alt="STRYD"
          />
        </ExternalLink>
        <NavBlockContainer>{links}</NavBlockContainer>
        <LinkStack>
          <InternalLink href="/select-store">
            <ShoppingCartIconContainer>
              <Image
                title={selectStoreMessage}
                src={regionConfig.flagIconSrc}
                alt={selectStoreMessage}
                height="18px"
                width="24px"
              />
              <IconLabelText>{selectStoreMessage}</IconLabelText>
            </ShoppingCartIconContainer>
          </InternalLink>
          <InternalLink href="/cart">
            <ShoppingCartIconContainer>
              <ShoppingCartIcon
                title={cartItemsLabel}
                containerStyle={{width: '100%', height: '100%'}}
                svgStyle={{stroke: colors.themes.dark.headerText}}
              />
              {totalNumberOfItems > 0 ? (
                <ShoppingCartItemCount>
                  {totalNumberOfItems}
                </ShoppingCartItemCount>
              ) : null}
              <IconLabelText>{cartItemsLabel}</IconLabelText>
            </ShoppingCartIconContainer>
          </InternalLink>
          <MenuBtnContainer>
            <MobileNavMenuButton
              showX={!!mobileNavHeight}
              onClick={toggleMobileNav}
            />
          </MenuBtnContainer>
        </LinkStack>
        <MobileNavBlockContainer
          style={{
            height: `${mobileNavHeight}px`,
          }}
        >
          <MobileNavBlock
            ref={mobileNavRef}
            style={{visibility: mobileNavHeight ? 'visible' : 'hidden'}}
          >
            {links}
          </MobileNavBlock>
        </MobileNavBlockContainer>
      </NavTopBar>
    </NavContainer>
  );
};
