import {useEffect, useState} from 'react';

import {useRouter} from 'next/router';
import {useIntl, FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {
  colors,
  dimensions,
  BorderOnlyActionButton,
  ActionButton,
  RemoveIcon,
  SvgStyle,
} from '@stryd/react-ui';

import {regionNames, ValidRegionCode} from 'src/config';
import {usePageRegionData} from 'src/contexts/PageRegionDataContext';
import {getCookie, setCookie, isRegionCookie} from 'src/utils/cookies';
import {getClientLocale} from 'src/utils/geoip';
import {withBasePath} from 'src/utils/router';

const PopupPositionContainer = styled.div`
  position: fixed;
  bottom: ${dimensions.defaultPadding};
  right: ${dimensions.defaultPadding};
  width: 100%;
  max-width: 400px;
  z-index: 999999;

  @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
    bottom: 0;
    right: 0;
    max-width: unset;
  }
`;

const PopupContainerInner = styled.div`
  position: relative;
  padding: calc(1.5 * ${dimensions.defaultPadding});
  background-color: ${colors.themes.dark.backgroundInverted};
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.12);
  border-radius: ${dimensions.defaultBorderRadius};

  @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
    border-radius: 0;
  }
`;

const PopupMessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const exitIconContainerStyle: React.CSSProperties = {
  position: 'absolute',
  top: dimensions.defaultPadding,
  right: dimensions.defaultPadding,
  cursor: 'pointer',
  height: '20px',
  width: '20px',
};

const exitIconStyle: SvgStyle = {
  fill: colors.themes.dark.text,
  width: '100%',
  height: '100%',
};

const PopupActionContainer = styled.div`
  flex-grow: 1;
  width: 50%;
  margin: 0 4px;
`;

const PopupActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  ${BorderOnlyActionButton}, ${ActionButton} {
    padding: ${dimensions.halfPadding} 0;
    width: 100%;
  }
`;

export const RegionHelper = (): JSX.Element | null => {
  const intl = useIntl();
  const router = useRouter();
  const pageContext = usePageRegionData();

  const [detectedRegion, setDetectedRegion] = useState<ValidRegionCode | null>(
    null
  );
  const [regionPref, setRegionPref] = useState<ValidRegionCode | null>(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const checkRegion = async (): Promise<void> => {
      const regionCookie = getCookie('user_pref_region');
      if (regionCookie && isRegionCookie(regionCookie)) {
        setRegionPref(regionCookie.value);
        return;
      }

      const res = await fetch(withBasePath(`/api/locale`));
      if (res.ok) {
        const {region} = await res.json();
        setDetectedRegion(region);
      } else {
        console.log('Failed to detect region');
      }
    };

    if (!detectedRegion && !regionPref) {
      checkRegion();
      return;
    }

    if (
      regionPref === pageContext.region ||
      detectedRegion === pageContext.region
    ) {
      return;
    }

    setShowPopup(true);
  }, [detectedRegion, regionPref, pageContext.region]);

  const redirectRegion = (region: ValidRegionCode): void => {
    const currentPath = router.asPath;
    const locale = getClientLocale(region, '');

    const newPath = `/${region}/${locale}${currentPath.substring(
      `/${pageContext.region}/${pageContext.locale}`.length
    )}`;
    router.push(newPath);
    setShowPopup(false);
  };

  const setRegionCookie = (): void => {
    setCookie(
      {key: 'user_pref_region', value: pageContext.region},
      {expires: 14}
    );
    setShowPopup(false);
  };

  const handleClosePopup = (): void => {
    setCookie({key: 'user_pref_region', value: pageContext.region});
    setShowPopup(false);
  };

  const region = regionPref || detectedRegion;

  if (!region || !showPopup) {
    return null;
  }

  const redirectButtonLabel = intl.formatMessage(
    {
      id: 'RegionHelper.redirectTo',
      defaultMessage: 'Go to {region}',
    },
    {region: regionNames[region]}
  );

  const stayOnPageButtonLabel = intl.formatMessage(
    {
      id: 'RegionHelper.redirectTo',
      defaultMessage: 'Stay on {region}',
    },
    {region: regionNames[pageContext.region]}
  );

  return (
    <PopupPositionContainer>
      <PopupContainerInner>
        <RemoveIcon
          containerStyle={exitIconContainerStyle}
          svgStyle={exitIconStyle}
          onClick={handleClosePopup}
        />
        <PopupMessageContainer>
          <p
            data-testid="location-confirm-message"
            style={{marginBottom: 0, paddingRight: dimensions.defaultPadding}}
          >
            <FormattedMessage
              id="RegionHelper.detectedRegion"
              defaultMessage="We think you are in {region}."
              values={{
                region: regionNames[region],
              }}
            />
            <br />
            <FormattedMessage
              id="RegionHelper.updateLocation"
              defaultMessage="Update your location?"
            />
          </p>
        </PopupMessageContainer>
        <PopupActions>
          <PopupActionContainer>
            <BorderOnlyActionButton
              onClick={setRegionCookie}
              title={stayOnPageButtonLabel}
              aria-label={stayOnPageButtonLabel}
            >
              {regionNames[pageContext.region]}
            </BorderOnlyActionButton>
          </PopupActionContainer>

          <PopupActionContainer>
            <ActionButton
              onClick={() => redirectRegion(region)}
              title={redirectButtonLabel}
              aria-label={redirectButtonLabel}
            >
              {regionNames[region]}
            </ActionButton>
          </PopupActionContainer>
        </PopupActions>
      </PopupContainerInner>
    </PopupPositionContainer>
  );
};
