import {ExternalLink, ExternalLinkProps} from './ExternalLink';
import {InternalLink, InternalLinkProps} from './InternalLink';

type LinkProps = InternalLinkProps | ExternalLinkProps;

/**
 * Wraps around internal and external link components and picks the appropriate one based on the passed href.
 * Href attributes starting with `http://` or `https://` will be treated as external links
 * Href attributes starting with anything else are considered internal, and will use next/link under the hood.
 *
 * If passing an internal link, you may also pass the `locale` or `region` props in order to
 * override the automatic localization based on current path.
 */
export const Link: React.FC<LinkProps> = (props) => {
  const isExternalLink = props.href ? /^http(s?):\/\//.test(props.href) : false;
  const LinkComponent = isExternalLink ? ExternalLink : InternalLink;

  return <LinkComponent {...props}>{props.children}</LinkComponent>;
};
