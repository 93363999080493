import Head from 'next/head';
import {MessageDescriptor, useIntl} from 'react-intl';

import {
  countryCodesByRegion,
  IRegionalStoreConfig,
  regionalConfigsArr,
  regionNames,
  ValidRegionCode,
} from 'src/config/regions';
import {STRYD_TWITTER_HANDLE} from 'src/config/social';
import {usePageRegionData} from 'src/contexts';
import {IWebPageFields} from 'src/utils/contentful/content-models/generated/contentful';
import {
  INDEX_PAGE_SLUG,
  isAllowedRegion,
} from 'src/utils/contentful/content-models/util';
import {withBasePath} from 'src/utils/router';

export interface SEOProps {
  title: string | MessageDescriptor;
  description: string | MessageDescriptor;
  imageSrc?: string;
  imageAltText?: string;
  includeInRegions: IncludedRegionsList;
  slug: string;
  slugPrefix: string;
  noIndex: boolean;
}

type IncludedRegionsList = IWebPageFields['includeInRegions'];

export interface IAlternateLocalePage {
  /** host url of the alternate page, with region/locale info appended, e.g. stryd.com/eu/en */
  localizedHost: string;
  /** should be formatted as `${lang}-${countryCode}`, e.g. "de-DE", or just the language (e.g. "de") */
  hrefLang: string;
}

const HOST_NAME = process.env.NEXT_PUBLIC_HOST_NAME;

if (!HOST_NAME) {
  throw new Error('Could not find `HOST_NAME` value in environment variables.');
}

const getAlternateLocaleHomePagesForRegion = (
  config: IRegionalStoreConfig<ValidRegionCode>
): IAlternateLocalePage[] => {
  const {locales, regionCode} = config;
  const countryCodes = countryCodesByRegion[regionCode];

  const langRegionCodes = countryCodes.reduce(
    (allLangRegions, currCountryCode) => {
      const currCountryWithLangs = locales.map((locale) => ({
        hrefLang: `${locale}-${currCountryCode}`,
        localizedHost:
          'https://' + HOST_NAME + withBasePath(`/${regionCode}/${locale}`),
      }));
      return [...allLangRegions, ...currCountryWithLangs];
    },
    [] as IAlternateLocalePage[]
  );

  return langRegionCodes;
};

export const getAlternateLocaleHomePagesForRegions = (
  includedRegions: IncludedRegionsList
): IAlternateLocalePage[] => {
  return regionalConfigsArr.reduce((allHrefLangs, currConfig) => {
    if (isAllowedRegion(currConfig.regionCode, includedRegions)) {
      const regionHrefLangs = getAlternateLocaleHomePagesForRegion(currConfig);
      return [...allHrefLangs, ...regionHrefLangs];
    }

    return allHrefLangs;
  }, [] as IAlternateLocalePage[]);
};

export const SEO: React.FC<SEOProps> = ({
  title,
  description,
  imageSrc,
  imageAltText,
  includeInRegions,
  slug,
  slugPrefix,
  noIndex,
}) => {
  const {region, locale} = usePageRegionData();
  const pagePath = slug === INDEX_PAGE_SLUG ? '/' : `${slugPrefix}/${slug}`;
  const pageUrl = HOST_NAME + withBasePath(`/${region}/${locale}`) + pagePath;

  const intl = useIntl();
  const formattedTitle =
    typeof title === 'string' ? title : intl.formatMessage(title);
  const formattedDescription =
    typeof description === 'string'
      ? description
      : intl.formatMessage(description);

  const siteName = `Stryd (${regionNames[region]})`;
  const pageTitleWithRegion = `${formattedTitle} | ${siteName}`;

  return (
    <Head>
      <title>{pageTitleWithRegion}</title>
      {noIndex ? <meta name="robots" content="noindex" /> : null}

      {/* Essential meta Tags */}
      <meta property="og:title" content={pageTitleWithRegion} />
      <meta property="og:description" content={formattedDescription} />
      <meta property="og:image" content={imageSrc} />
      <meta property="og:url" content={pageUrl} />
      <meta name="twitter:card" content="summary" />
      <meta name="description" content={formattedDescription} />

      {/* Non-Essential meta, But Recommended */}
      <meta property="og:site_name" content={siteName} />
      <meta name="twitter:image:alt" content={imageAltText} />
      <meta name="twitter:site" content={STRYD_TWITTER_HANDLE} />

      {/* Specify alternate page language/region location links for SEO*/}
      {getAlternateLocaleHomePagesForRegions(includeInRegions).map(
        ({hrefLang, localizedHost}) => (
          <link
            key={hrefLang}
            rel="alternate"
            hrefLang={hrefLang}
            href={localizedHost + pagePath}
          />
        )
      )}
    </Head>
  );
};
