import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-datetimeformat/polyfill';
// Do we need this?
// import '@formatjs/intl-datetimeformat/add-all-tz';
import '@formatjs/intl-pluralrules/polyfill';

import {IntlProvider} from 'react-intl';

import {SupportedLocale} from 'src/config';

import de from '../intl/de.json';
import en from '../intl/en.json';
import es from '../intl/es.json';
import fr from '../intl/fr.json';

// locale-data for en
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-datetimeformat/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/en';

// locale-data for de
import '@formatjs/intl-numberformat/locale-data/de';
import '@formatjs/intl-datetimeformat/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/de';

// locale-data for fr
import '@formatjs/intl-numberformat/locale-data/fr';
import '@formatjs/intl-datetimeformat/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/fr';

// locale-data for es
import '@formatjs/intl-numberformat/locale-data/es';
import '@formatjs/intl-datetimeformat/locale-data/es';
import '@formatjs/intl-pluralrules/locale-data/es';

const messages: Record<SupportedLocale, Record<string, string>> = {
  en,
  de,
  fr,
  es,
};

export interface ReactIntlProviderProps
  extends React.ComponentProps<typeof IntlProvider> {
  locale: SupportedLocale;
}

export const ReactIntlProvider: React.FC<ReactIntlProviderProps> = ({
  children,
  locale,
  ...contextProps
}) => {
  return (
    <IntlProvider locale={locale} messages={messages[locale]} {...contextProps}>
      {children}
    </IntlProvider>
  );
};
