import styled from 'styled-components';

import {colors} from '@stryd/react-ui';

import {withBasePath} from 'src/utils/router';

const PreviewMessage = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: ${colors.themes.dark.textHighlight};
  color: ${colors.themes.dark.headerText};
  z-index: 9999;
  padding: 8px 20px;
  border-radius: 0 0 8px 8px;
  letter-spacing: 0.05rem;
  cursor: pointer;
`;

export const PreviewIndicator: React.FC = () => {
  const handleExitPreviewMode = async () => {
    const res = await fetch(withBasePath(`/api/preview/exit`));
    if (res.ok) {
      window.location.reload();
    } else {
      alert(`Error: failed to exit preview mode\n\n${res.json()}`);
    }
  };
  return (
    <PreviewMessage onClick={handleExitPreviewMode}>
      Exit Preview Mode
    </PreviewMessage>
  );
};
