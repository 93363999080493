import {StyledAnchor} from './StyledAnchor';

export interface ExternalLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
}

export const ExternalLink: React.FC<ExternalLinkProps> = (props) => {
  const {children, ...anchorProps} = props;

  return (
    <>
      {anchorProps.href.includes('stryd.com') ? (
        <StyledAnchor target={'_self'} {...anchorProps}>
          {children}
        </StyledAnchor>
      ) : (
        <StyledAnchor target={'_blank'} rel={'noopener'} {...anchorProps}>
          {children}
        </StyledAnchor>
      )}
    </>
  );
};
